import * as React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "gatsby";
import { container, styledLink, title1 } from "./ManageReservation.module.scss";

export const StyledContainer = (props: any) => {
    return (<Container className={`${container} pt-5 pb-5`} fluid="sm">{props.children}</Container>);
};
export const StyledLink = (props: { children: string, to: string, id?: string}) => {
    return (<Link id={props.id} className={styledLink} {...props} > {props.children}</Link>);
};

export const Title1 = (props: any) => {
    return (<h1 id={props.id} className={title1}>{props.children}</h1>);
  };

